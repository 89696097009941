import React from "react";
import CookieToastColumn from "../CookieToastColumn";
import Link from "../Link";
import Icons from "../Icons";
import Icon from "../Icon";
import styles from "./footer.module.less";

const Footer = () => (
  <footer id="footer" className={styles["footer"]}>
    <div className={styles["footer__cookie-toast"]}>
      <CookieToastColumn
        toasts={[
          {
            name: "obbyCookieConfirmation8",
            text:
              "We use cookies to enhance your user experience, improve our site and better our product. By continuing to browse your agree to our cookie policy."
          }
        ]}
      />
    </div>

    <div className={styles["footer__container"]}>
      <Icon
        className={styles["footer__logo"]}
        icon={Icons.RobbinsEggBlueObbyLogo}
        width={100}
      />
      <div className={styles["footer__aside"]}>
        <h2 className={styles["footer__title"]}>
          DISCOVER THE NEW WAY TO LEARN
        </h2>
        <h3 className={styles["footer__description"]}>
          With Obby you can take the next step in your career, discover a new
          skill or help spark your curiousity, give it a go!
        </h3>
        <div className={styles["footer__social-icons"]}>
          <a
            href="https://www.facebook.com/obbyuk/"
            className={styles["footer__social-icon"]}
            target="_blank"
          >
            <Icon icon={Icons.Facebook} height={20} />
          </a>
          <a
            href="https://www.instagram.com/obbyuk/"
            className={styles["footer__social-icon"]}
            target="_blank"
          >
            <Icon icon={Icons.Instagram} height={20} />
          </a>
          <a
            href="https://twitter.com/ObbyUK"
            className={styles["footer__social-icon"]}
            target="_blank"
          >
            <Icon icon={Icons.Twitter} height={20} />
          </a>
        </div>
      </div>
      <div className={styles["footer__mobile-links"]}>
        <Link href="/about-us" className={styles["footer__link"]}>
          <h4>About</h4>
        </Link>
        <a
          onClick={e => {
            console.group("clicked");
            e.preventDefault();

            if (window.Beacon) {
              window.Beacon("open");
            }
          }}
          className={styles["footer__link"]}
        >
          <h4>Help</h4>
        </a>
        <Link href="/terms" className={styles["footer__link"]}>
          <h4>Terms</h4>
        </Link>
      </div>
      <div className={styles["footer__link-sections"]}>
        <div className={styles["footer__link-section"]}>
          <h4 className={styles["footer__links-title"]}>Learning</h4>
          <ul className={styles["footer__link-list"]}>
            <li className={styles["footer__link-list-item"]}>
              <Link href="/about-us" className={styles["footer__link"]}>
                About us
              </Link>
            </li>
            <li className={styles["footer__link-list-item"]}>
              <Link
                onClick={e => {
                  e.preventDefault();
                  if (window.Beacon) {
                    window.Beacon("open");
                  }
                }}
                className={styles["footer__link"]}
              >
                Help
              </Link>
            </li>
            <li className={styles["footer__link-list-item"]}>
              <Link href="/terms" className={styles["footer__link"]}>
                Terms
              </Link>
            </li>
            <li className={styles["footer__link-list-item"]}>
              <Link href="/about-us" className={styles["footer__link"]}>
                Careers
              </Link>
            </li>
          </ul>
        </div>
        <div className={styles["footer__link-section"]}>
          <h4 className={styles["footer__links-title"]}>Discover</h4>
          <ul className={styles["footer__link-list"]}>
            <li className={styles["footer__link-list-item"]}>
              <a
                href="https://www.baluu.co.uk/blog/best-booking-system-for-small-businesses"
                className={styles["footer__link"]}
              >
                Best Booking Systems
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div id="end-of-footer" style={{ height: "1px", width: "100%" }} />
  </footer>
);

export default Footer;
