import { evolve } from "ramda";

const SingleChatMessages__ReversedDirectionSingleChatMessages = messages =>
  messages.map(
    evolve({
      direction: direction => (direction === "left" ? "right" : "left")
    })
  );

export default SingleChatMessages__ReversedDirectionSingleChatMessages;
