import { merge, uniq, map, prop, pipe, contains } from "ramda";

const doTheMessagesContainTheGivenSenderId = (
  leftDirectionSenderId,
  messages
) =>
  pipe(map(prop("senderId")), uniq, contains(leftDirectionSenderId))(messages);

const SingleChatMessagesAndLeftDirectionSenderId__SingleChatMessages = (
  leftDirectionSenderId,
  messages
) => {
  if (doTheMessagesContainTheGivenSenderId(leftDirectionSenderId, messages)) {
    return messages.map(message =>
      merge(message, {
        direction: message.senderId === leftDirectionSenderId ? "left" : "right"
      })
    );
  }
  return messages;
};

export default SingleChatMessagesAndLeftDirectionSenderId__SingleChatMessages;
