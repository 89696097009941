import { useState, useMemo } from "react";
import * as Yup from "yup";
import _ from "lodash";
import flatten from "flat";

export function useFormState(args = {}) {
  // properly destruct schema from args
  const [{ schema, initialValues }] = useState(() => {
    let { schema = {}, values = {} } = args;
    schema = Yup.isSchema(schema) ? schema : Yup.object(schema);
    return {
      schema,
      initialValues: schema.cast(values)
    };
  });
  const context = useMemo(() => args.context, [args.context]);

  const [state, setState] = useState(() => {
    let errors = _validate(schema, args.values || {}, undefined, { context });
    return {
      values: initialValues,
      touched: {},
      errors: errors === true ? {} : errors,
      valid: errors === true,
      changed: false
    };
  });
  const { changed, values, errors, touched, valid } = state;

  function addArrayElement(path, value = {}) {
    setState(state => {
      const array = path ? _.get(state.values, path) : state.values;
      if (!Array.isArray(array)) return state;
      const initialValue = Yup.reach(
        schema,
        path ? `${path}.${array.length}` : `${array.length}`
      ).cast(value);
      const values = _setValue(
        state.values,
        path ? `${path}.${array.length}` : `${array.length}`,
        initialValue
      );
      const errors = _validate(schema, values, undefined, { context });

      return {
        ...state,
        values,
        errors,
        valid: errors === true,
        changed: !_.isEqual(values, initialValues)
      };
    });
  }

  function addArrayElements(path, index, count = 1, initialValues = []) {
    setState(state => {
      let values = state.values;
      {
        const array = _.get(state.values, path);
        if (!Array.isArray(array)) return state;

        const elements = new Array(Math.max(0, count))
          .fill(null)
          .map((element, index) =>
            Yup.reach(schema, `${path}.0`).cast(initialValues[index])
          );

        array.splice(index, Math.max(0, -count), ...elements);
        values = _setValue(state.values, path, array);
      }

      let touched = state.touched;
      {
        const array = _.get(touched, path);
        if (Array.isArray(array)) {
          array.splice(
            index,
            Math.max(0, -count),
            ...Array(Math.max(0, count))
          );
          touched = _.set(touched, path, array);
        }
      }

      const errors = _validate(schema, values, undefined, { context });

      return {
        ...state,
        values,
        touched,
        valid: errors === true,
        changed: !_.isEqual(values, initialValues)
      };
    });
  }

  function defaults(path, value = {}) {
    return Yup.reach(schema, path).cast(value);
  }

  function removeArrayElement(path, index) {
    let values = state.values;
    let element;
    {
      const array = _.get(state.values, path);
      if (!Array.isArray(array)) return;
      [element] = array.splice(index, 1);
      values = _setValue(values, path, array);
    }

    let touched = state.touched;
    {
      const array = _.get(touched, path);
      if (Array.isArray(array)) {
        array.splice(index, 1);
        touched = _.set(touched, path, array);
      }
    }

    let errors = state.errors;
    {
      const array = _.get(errors, path);
      if (Array.isArray(array)) {
        array.splice(index, 1);
        errors = _.set(errors, path, array);
      }
    }

    setState({
      ...state,
      values,
      errors,
      touched,
      changed: !_.isEqual(values, initialValues)
    });

    return element;
  }

  function reset() {
    setState(state => {
      const values = schema.default();
      return {
        ...state,
        values,
        touched: {},
        changed: !_.isEqual(values, initialValues)
      };
    });
  }

  function revert() {
    setState(state => ({
      ...state,
      values: initialValues,
      touched: {},
      changed: false
    }));
  }

  function setFieldTouched(path) {
    setState(state => {
      const touched = _setTouched(state.touched, path);
      return {
        ...state,
        touched
      };
    });
  }

  function setFieldValue(path, value, touch) {
    setState(state => {
      let values = _setValue(state.values, path, value);
      values = schema.cast(values);

      const errors = _validate(schema, values, undefined, { context });
      return {
        ...state,
        values,
        errors: errors === true ? {} : errors,
        valid: errors === true,
        ...(touch ? { touched: _setTouched(state.touched, path) } : {}),
        changed: !_.isEqual(values, initialValues)
      };
    });
  }

  function setFieldValues(values) {
    setState(state => {
      values = Object.entries(values).reduce(
        (values, [path, value]) => _setValue(values, path, value),
        { ...state.values }
      );

      values = schema.cast(values);
      const errors = _validate(schema, values, undefined, { context });

      return {
        ...state,
        values,
        errors: errors === true ? {} : errors,
        valid: errors === true,
        changed: !_.isEqual(values, initialValues)
      };
    });
  }

  function isValid(path) {
    const errors = _validate(schema, values, path, { context });
    return errors === true;
  }

  // This method no longer needs to be async.
  async function validate(path) {
    const errors = _validate(schema, values, path, { context });
    if (errors !== true) throw errors;
    return true;
  }

  function evaluate(path) {
    const evaluatedErrors = _validate(schema, values, path, { context });
    if (evaluatedErrors === true) return true;
    const touched = { ...state.touched };
    let errors = { ...state.errors };
    if (path) {
      errors = _.set(errors, path, evaluatedErrors);
      errors = _.pick(errors, path);
    }

    const errorPaths = Object.keys(flatten(errors));
    // get all the paths covered by the errors
    const touchedPaths = Object.keys(flatten(_.pick(state.values, errorPaths)));
    touchedPaths.forEach(path => _.set(touched, path, true));
    setState({
      ...state,
      touched,
      errors
    });
    return evaluatedErrors;
  }

  // This method no longer needs to be async.
  async function submit(path) {
    return evaluate(path) === true;
  }

  return {
    values,
    touched,
    errors,
    changed,
    valid,
    addArrayElement,
    addArrayElements,
    defaults,
    evaluate,
    isValid,
    removeArrayElement,
    reset,
    revert,
    schema,
    setFieldTouched,
    setFieldValue,
    setFieldValues,
    submit,
    validate
  };
}

function _setValue(values, path, value) {
  // const state = Array.isArray(values) ? [...values] : { ...values };
  const state = _.cloneDeep(values);
  switch (typeof path) {
    case "string":
      _.set(state, path, value);
      break;
    case "object":
      _.merge(state, path);
      break;
  }
  return state;
}

function _setTouched(touched, path) {
  // already touched
  if (_.get(touched, path)) return touched;
  const newTouched = { ...touched };
  _.set(newTouched, path, true);
  return newTouched;
}

function _validate(schema, values, path, options = {}) {
  try {
    if (path)
      schema.validateSyncAt(path, values, { abortEarly: false, ...options });
    else schema.validateSync(values, { abortEarly: false, ...options });
    return true;
  } catch (e) {
    if (!Array.isArray(e.inner)) return {};
    const errors = e.inner.reduce((errors, error) => {
      // skip this error if we already have another error assigned to the current path
      return _.has(errors, error.path)
        ? errors
        : _.set(errors, error.path, error.message);
    }, {});
    return path ? _.get(errors, path) : errors;
  }
}
