import { map, multiply, range } from "ramda";

export default (hours, minutes, startingTime) => {
  hours = hours || 24;
  minutes = minutes || 15;
  startingTime = startingTime || 0;

  var numberOfHourParts = 60 / minutes;
  var numberOfIterations = hours * numberOfHourParts;

  return map(multiply(minutes), range(startingTime, numberOfIterations));
};
